import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'

if ('serviceWorker' in navigator) {
window.addEventListener('load', () => {
    navigator.serviceWorker.register(new URL('../public/service-worker.js', import.meta.url), { type: 'module' })
    .then((registration) => {
        console.log('SW registered: ', registration);
    })
    .catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
    });
});
}
const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <Canvas
        className="r3f"
        camera={ {
            fov: 9,
            near: 0.1,
            far: 2000,
            position: [ -5, 1.5, 4 ]
        } }
    >
        <Experience/>
    </Canvas>
)





