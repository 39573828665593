import { useRef } from 'react'
import { 
    Text, 
    Html, 
    ContactShadows, 
    PresentationControls,
    Float, 
    Environment, 
    useGLTF 
} from '@react-three/drei'

import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'


export default function Experience()
{
    const phone = useGLTF('./models/scene.gltf')
    const model = useRef()
    const text = useRef()
    const container = useRef()
    
    useGSAP(() => {
        gsap.from(model.current.position, 
        { 
            y: "0.5", 
            x: "0.5",
            duration: 1.2,
            ease: 'power1.out'
        })

        gsap.from(text.current.position, { 
                y: "-0.5", 
                x: "-0.5",
                duration: 1.2,
                ease: 'power1.out'
        }) 
    })
    

    return <>

        <color args={ [ '#4100d6' ] } attach="background" />

        <Environment preset="city" />
        
        <PresentationControls
            global
            rotation={ [ 0.13, - 0.1, 0 ] }
            polar={ [ - 0.4, 0.2 ] }
            azimuth={ [ - 1, 0.75 ] }
            config={ { mass: 2, tension: 400 } }
            snap={ { mass: 4, tension: 400 } }
        >
            <Float 
                ref ={container} 
                rotationIntensity={ 0.1 } 
                floatingRange={ [ 0, 0.08 ] }
            >  
                <rectAreaLight
                    width={ 2.5 }
                    height={ 1.65 }
                    intensity={ 65 }
                    color={ '#ff6900' }
                    rotation={ [ - 0.1, Math.PI, 0 ] }
                    position={ [ 0, 0.55, - 1.15 ] }
                />
                

                <primitive
                    ref={ model }
                    object={ phone.scene }
                    scale={ [ 1, 1, -1 ] }
                    rotation-x={ - 0.18 }
                    rotation-y={ - 1}
                >
                    <Html
                        transform
                        wrapperClass="htmlScreen"
                        distanceFactor={ 0.5}
                        position={ [ -0, -0.015, -0.018 ] }
                    >
                        <iframe src="https://darkjoker.com" />
                    </Html>
                </primitive>
                    <Text
                        ref={ text}
                        font="./bangers-v20-latin-regular.woff"
                        fontSize={ 0.08 }
                        rotation-y={ - 1 }
                        position={ [ - 0.1, - 0.5, 0.01] }
                        maxWidth={ 2 }
                        color={'#009874'}
                    >
                        Jack Lee
                    </Text>
            </Float>
        </PresentationControls>

        <ContactShadows
            position-y={ - 0.48}
            opacity={ 0.8 }
            scale={ 4 }
            blur={ 3 }
        />
    </>
}